export const environment = {
  production: false,
  storeApiUrl: 'https://api-dev.martsup.com/storedev/api',
  catApiUrl: 'https://api-dev.martsup.com/categorydev/api',
  prodApiUrl: 'https://api-dev.martsup.com/productdev/api',
  publishApiUrl: 'https://api-dev.martsup.com/publishdev/api',
  reportApiUrl: 'https://api-dev.martsup.com/reportdev/api',
  userApiUrl: 'https://api-dev.martsup.com/userdev/api',
  profileImageUrl: 'https://martsup-dev.s3.ap-south-1.amazonaws.com/ProfileImage',
  storeUrl:'https://www.my-dev.martsup.com/'
};

